export const schedule = [
  {
    classId: "0",
    classTitle: "STREET JAZZ / ENFANTS",
    groups: [
      {
        name: "4 à 5ans",
        day: 2,
        hours: "15h à 16h",
        place: 3,
      },
      {
        name: "",
        day: 5,
        hours: "9h30 à 10h30",
        place: 2,
      },
      {
        name: "6 à 8ans",
        day: 2,
        hours: "14h à 15h",
        place: 3,
      },
      {
        name: "",
        day: 2,
        hours: "14h à 15h",
        place: 4,
      },
      {
        name: "",
        day: 2,
        hours: "16h à 17h",
        place: 4,
      },
      {
        name: "",
        day: 5,
        hours: "11h à 12h",
        place: 1,
      },
      {
        name: "9 à 11ans",
        day: 2,
        hours: "15h à 16h",
        place: 4,
      },
      {
        name: "",
        day: 2,
        hours: "17h à 18h",
        place: 4,
      },
      {
        name: "",
        day: 5,
        hours: "10h30 à 11h30",
        place: 2,
      },
    ],
  },
  {
    classId: "1",
    classTitle: "STREET JAZZ /  TEEN - ADOS - ADULTES",
    groups: [
      {
        name: "Street Jazz Niv1-2 Teen-Ados",
        day: 2,
        hours: "17h45 à 19h",
        place: 3,
      },
      {
        name: "Street Jazz Niv3-4 Teen-Ados-Adultes",
        day: 1,
        hours: "18h30 à 20h",
        place: 4,
      },
    ],
  },
  {
    classId: "2",
    classTitle: "STREET JAZZ / CHORÉS / *ADOS (14 ans et +) - ADULTES",
    groups: [
      {
        name: "Prépa Choré *Ados (14 ans et +) - Adultes",
        day: 2,
        hours: "18h15 à 19h15",
        place: 4,
        newItems: ["name"]
      },
      {
        name: "Choré 3 Ados (16ans et +) - Adultes",
        day: 2,
        hours: "20h15 à 21h45",
        place: 4,
      },
    ],
  },
  {
    classId: "3",
    classTitle: "STREET JAZZ / ADULTES (à partir de 18 ans)",
    groups: [
      {
        name: "Street Jazz Adulte Débutant/Moyen",
        day: 0,
        hours: "20h à 21h15",
        place: 4,
      },
      {
        name: "Street Jazz Adulte Avancé",
        day: 1,
        hours: "20h15 à 21h45",
        place: 4,
        newItems: ["hours"],
      },
    ],
  },
  {
    classId: "6",
    classTitle: "RAGGA / 9-11 ANS - TEEN - ADOS - ADULTES",
    groups: [
      {
        name: "Ragga 9-11 ans",
        day: 1,
        hours: "17h15 à 18h15",
        place: 3,
        newItems: ["place", "day", "hours"],
      },
      {
        name: "Ragga Niv1 Teen-Ados",
        day: 1,
        hours: "18h15 à 19h15",
        place: 3,
        newItems: ["name", "day", "hours"],
      },
      {
        name: "Ragga Niv2 Teen-Ados-Adultes",
        day: 3,
        hours: "17h45 à 19h",
        place: 4,
        newItems: ["name", "hours"],
      },
      {
        name: "Ragga Adultes Open Level",
        day: 2,
        hours: "19h15 à 20h15",
        place: 4,
      },
    ],
  },
  {
    classId: "7",
    classTitle: "CONTEMPORAIN URBAIN / 8-11 ANS - TEEN - ADOS - ADULTES",
    groups: [
      {
        name: "Contempo Kids 8-11ans",
        day: 2,
        hours: "15h30 à 16h30",
        place: 5,
        newItems: ["name"],
      },
      {
        name: "Contempo Niv1 Teen-Ados-Adultes",
        day: 1,
        hours: "19h15 à 20h30",
        place: 3,
        newItems: ["name", "day", "hours", "place"],
      },
      {
        name: "",
        day: 2,
        hours: "16h30 à 18h",
        place: 5,
      },
      {
        name: "Contempo Niv2 Teen-Ados-Adultes",
        day: 3,
        hours: "19h à 20h15",
        place: 4,
        newItems: ["name", "day", "hours", "place"],
      },
      {
        name: "Contempo Niv2 Adultes",
        day: 3,
        hours: "20h15 à 21h45",
        place: 4,
        newItems: ["name", "day", "hours", "place"],
      },
      {
        name: "Contempo Adultes Open Level",
        day: 2,
        hours: "20h à 21h15",
        place: 3,
        newItems: ["name", "day", "hours", "place"],
      },
    ],
  },
  {
    classId: "8",
    classTitle: "HIP-HOP / 8-11 ANS - TEEN - ADOS - ADULTES",
    groups: [
      {
        name: "Hip-Hop Kids 8-11ans",
        day: 0,
        hours: "17h30-18h30",
        place: 4,
        newItems: ["name", "day", "hours", "place"],
      },
      {
        name: "",
        day: 2,
        hours: "14h30-15h30",
        place: 5,
      },
      {
        name: "Hip-Hop Niv1 Teen-Ados-Adultes",
        day: 4,
        hours: "17h30 à 18h30",
        place: 2,
      },
      {
        name: "Hip-Hop Niv1-2 Teen-Ados-Adultes",
        day: 4,
        hours: "18h30 à 19h30",
        place: 2,
      },
      {
        name: "Hip-Hop Niv2-3 Teen-Ados-Adultes",
        day: 4,
        hours: "19h30 à 20h30",
        place: 2,
        newItems: ["name", "day", "hours", "place"],
      },
    ],
  },
  {
    classId: "9",
    classTitle: "Commercial / *ADOS (14 ans et +) - ADULTES",
    groups: [
      {
        name: "Commercial *Ados (14 ans et +) - Adultes Open Level",
        day: 2,
        hours: "19h à 20h",
        place: 3,
        newItems: ["name", "day", "hours", "place"],
      },
    ],
  },
  {
    classId: "10",
    classTitle: "Girly / Ados-Adultes",
    groups: [
      {
        name: "Girly Ados-Adultes Open Level",
        day: 0,
        hours: "19h à 20h",
        place: 4,
        newItems: ["name"],
      },
    ],
  },
]

export const fitSchedule = [
  {
    day: 0,
    classes: [
      {
        hours: "19h30 à 20h30",
        name: "Cardio et Circuit Training",
        newItems: ["name"],
      },
      {
        hours: "20h30 à 21h30",
        name: "Zumba",
        newItems: ["name"],
      },
    ],
  },
  {
    day: 1,
    classes: [
      {
        hours: "20h30 à 21h30",
        name: "Dynamix",
        newItems: ["name"],
      },
    ],
  },
  {
    day: 3,
    classes: [
      {
        hours: "19h30 à 20h30",
        name: "Step-Fun",
        newItems: ["name"],
      },
      {
        hours: "20h30 à 21h30",
        name: "Tonicball Et Flexibilité",
        newItems: ["day", "name"],
      },
    ],
  },
]
